import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

import SubSlider from '../../../common/Slider/SubSlider';
import { renderCardPrice } from '../../../../utils/helpers/formater';

const Cards = ({ detailList, viva, offers }) => {
  const router = useRouter();

  // Replace maybe with an anchor tag and put this function inside a onClick for user
  const redirectToDetailView = useCallback((id) => {
    router.push(`/objektdetails/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {offers?.items?.length !== 0 && offers?.loadingState === false ? (
        <>
          <main
            className="cards"
            data-cy="listings"
            aria-hidden
            style={
              viva === 1
                ? {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                    alignItems: 'start',
                  }
                : null
            }
          >
            {offers.items.map((item) => (
              <div className="cards__container" key={item.id}>
                <article
                  role="presentation"
                  className="cards__container--inner"
                  style={{ minHeight: '240px' }}
                  key={item.id}
                >
                  <div className="cards__img" style={{ height: '240px' }}>
                    <SubSlider
                      isShowIndicators
                      items={item}
                      images={item.images}
                      detailList={detailList}
                      isCard
                    />
                  </div>
                  <section className="cards__details">
                    <div className="cards__rent-size-room">
                      <p>
                        <strong>{`${item.pricePerSqm} €/m²`}</strong>
                        <br />
                        <span>{`${
                          item.netRent ? 'Kaltmiete' : 'Kaufpreis'
                        }`}</span>
                      </p>
                      <p>
                        <strong>{`${item.livingSpace.toFixed(0)} m²`}</strong>
                        <br />
                        <span>Wohnfläche (ca)</span>
                      </p>
                      <p>
                        <strong>{item.numberOfRooms}</strong>
                        <br />
                        <span>Zimmer</span>
                      </p>
                    </div>
                    <header className="cards__header">
                      <Link
                        onKeyDown={(e) =>
                          e.key === 'Enter' && redirectToDetailView(item.id)
                        }
                        tabIndex={0}
                        href={`/objektdetails/${item.id}`}
                      >
                        <a data-cy="card-title">
                          <div className="cards__header--title">
                            <span style={{ marginRight: '5px' }}>
                              {item.street && item.houseNumber
                                ? `${item.street} ${item.houseNumber}, `
                                : `${item.city} - ${item.district}, `}
                            </span>
                            <span>{`${item.zipCode} ${item.city}`}</span>
                          </div>
                          {item.netRent && (
                            <span
                              style={{ display: 'block', fontWeight: 'bold' }}
                            >
                              Gesamtmiete: &nbsp;
                              {item.warmRent && renderCardPrice(item.warmRent)}
                            </span>
                          )}
                        </a>
                      </Link>
                    </header>
                  </section>
                </article>
              </div>
            ))}
          </main>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
            flexDirection: 'column',
          }}
        >
          <p>No result found</p>
        </div>
      )}
    </>
  );
};

Cards.propTypes = {
  detailList: PropTypes.bool,
  viva: PropTypes.number.isRequired,
  offers: PropTypes.shape({
    loadingState: PropTypes.bool,
    items: PropTypes.array,
  }),
};

Cards.defaultProps = {
  detailList: false,
  offers: { items: [], loadingState: false },
};

export default Cards;

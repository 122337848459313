import PropTypes from 'prop-types';

import DownloadExcel from '../../components/ExcelDownload';
import Cards from '../../components/Cards';
import Loader from '../../../common/Loader';
import style from '../../Mietpolizei.module.scss';

const DisplayMietpolizeiResults = ({ filteredResultsWithSqm }) => (
  <>
    {filteredResultsWithSqm.loadingState ? (
      <div
        className="container"
        style={{ position: 'relative', height: '200px' }}
      >
        <Loader />
      </div>
    ) : (
      <div className="container" style={{ maxWidth: '1400px' }}>
        <div className={style.excelDownloadPosition}>
          <DownloadExcel
            filteredResultsWithSqm={filteredResultsWithSqm.items}
          />
        </div>
        <Cards
          viva={1}
          offers={filteredResultsWithSqm}
          isImmobilienPage={false}
          detailList={false}
        />
      </div>
    )}
  </>
);

DisplayMietpolizeiResults.propTypes = {
  filteredResultsWithSqm: PropTypes.object,
};
DisplayMietpolizeiResults.defaultProps = {
  filteredResultsWithSqm: { items: [], loadingState: false },
};

export default DisplayMietpolizeiResults;

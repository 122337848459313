import Link from 'next/link';
import classNames from 'classnames';

import styles from './index.module.scss';

const FooterBar = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <div
        className="g-color-white-opacity-0_8 g-py-60"
        style={{ background: '#f6f3f3' }}
      >
        <div className={styles.footerPosition}>
          <div
            className={classNames('row justify-content-between', styles.box)}
          >
            <div
              className={classNames(
                'col-sm d-flex justify-content-center',
                styles.alignCenter,
              )}
            >
              <img
                src="/assets/img/logo-anthranzit.png"
                alt="logo"
                height={60}
                style={{ objectFit: 'contain', paddingLeft: '0' }}
                className="col-8"
              />
            </div>
            {/* eslint-disable-next-line max-len */}
            <div
              className={classNames(
                'col-sm  d-flex flex-column text-center justify-content-center align-items-center g-pt-5 g-pb-5',
                styles.alignCenter,
              )}
            >
              <address className="g-bg-no-repeat g-font-size-14 mb-0">
                <div className="d-flex">
                  <p className="mb-0" style={{ color: '#626262' }}>
                    IIB Institut <br /> Bahnhofanlage 3 | 68723 Schwetzingen{' '}
                    <br />
                    Tel: 06202 - 950 49 10 | Fax: 06202 - 923487
                    <br />
                    info@iib-institut.de
                  </p>
                </div>
              </address>
            </div>
            {/* eslint-disable-next-line max-len */}
            <div
              className={classNames(
                'col-sm d-flex flex-column text-right align-items-end justify-content-center g-pt-5 g-pb-5',
                styles.alignCenter,
              )}
            >
              <ul className="list-unstyled mb-0">
                <li className="g-pos-rel g-brd-white-opacity-0_1 g-pb-10">
                  <p className="g-pr-20 mb-0">
                    <Link href="/impressum">
                      <a className={styles.link}>Impressum</a>
                    </Link>
                    <span style={{ color: '#626262' }}> |</span>
                  </p>
                </li>
                <li className="g-pos-rel g-brd-white-opacity-0_1 g-pb-10">
                  <p className="g-pr-20 mb-0">
                    <Link href="/datenschutz">
                      <a className={styles.link}>Datenschutz</a>
                    </Link>
                    <span style={{ color: '#626262' }}> |</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <footer className={classNames('g-py-15', styles.backgroundColor)}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center text-md-left g-mb-10 g-mb-0--md">
              <div className="d-lg-flex">
                <p className="d-block g-mr-10 g-mb-10 g-mb-0--md g-color-white">
                  {`copyright ${currentYear} - ein Service von Zukunfts-, Markt- und Preisforschung | IIB Institut`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterBar;

import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Parallax } from 'react-parallax';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { fetchAutoSuggestText } from '../../store/market/actions';

import {
  DistrictsDropdown,
  FilterBySquaremeter,
  FilterByLivingSpace,
} from './components/DropDown/dropdowns';
import styles from '../Home/Home.module.scss';
import style from './Mietpolizei.module.scss';
import DisplayMietpolizeiResults from './layout/MietpolizeiResults';
import Header from './layout/TopBar';
import Footer from './layout/FooterBar';
import ButtonTooltip from './components/ButtonTooltip';

const Mietpolizeit = () => {
  const [filteredCityResults, setFilteredCityResults] = useState(0);
  const [districtName, setDistrictName] = useState('');
  const [completeDistrictObject, setCompleteDistrictObject] = useState({});
  const [sqmFilterFrom, setSqmFilterFrom] = useState('');
  const [sqmFilterTo, setSqmFilterTo] = useState('');
  const [livingSpaceFilterFrom, setLivingSpaceFilterFrom] = useState('');
  const [livingSpaceFilterTo, setLivingSpaceFilterTo] = useState('');
  const [tagResult, setTagResult] = useState([]);
  const [filteredResultsWithSqm, setFilteredResultsWithSqm] = useState({
    items: [],
    loadingState: false,
  });
  const [place, setPlace] = useState('');
  const [isChoosePlace, setChoosePlace] = useState(false);
  const [selectedChoosePlace, setSelectedChoosePlace] = useState({});
  const [autoSuggestPlaces, setSuggestPlaces] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth0();
  const [rolePermission, setRolePermission] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const findPermission = user?.['https://iib-institut.de/roles'].includes(
        'ROLE_MARKTMONITOR_USER',
      );
      setRolePermission(findPermission);
    }
  }, [user, isAuthenticated, setRolePermission]);

  // Fetch autosuggest citylist
  useEffect(() => {
    if (place.length > 3 && !isChoosePlace) {
      dispatch(fetchAutoSuggestText(place))
        .then((res) => {
          if (res?.data?.data?.length && place.length !== 0) {
            setSuggestPlaces(res.data.data);
          }
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, place]);

  // Set selected city data by clicking item
  const handlePlaceChanged = useCallback(
    (e) => {
      setPlace(e.target.value);
      if (place.length < 3) {
        setChoosePlace(false);
        setSuggestPlaces([]);
      }
    },
    [place],
  );

  // Set selected city data by hitting enter
  const renderPlacesOnEnter = useCallback((autoPlace) => {
    setChoosePlace(true);
    setPlace(autoPlace.title);
    setSelectedChoosePlace(autoPlace);
    setSuggestPlaces([]);
  }, []);

  // Navigate the autosuggestlist
  const navigateAutoPlaces = useCallback(
    (e) => {
      if (
        (e.key === 'ArrowDown' || e.key === 'Down') &&
        autoSuggestPlaces.length &&
        selectItem < autoSuggestPlaces.length - 1
      ) {
        setSelectItem((prev) => prev + 1);
      } else if (
        (e.key === 'ArrowUp' || e.key === 'Up') &&
        autoSuggestPlaces.length &&
        selectItem > 0
      ) {
        setSelectItem((prev) => prev - 1);
      } else if (e.key === 'Enter' && autoSuggestPlaces.length > 0) {
        renderPlacesOnEnter(autoSuggestPlaces[selectItem]);
      }
    },
    [autoSuggestPlaces, selectItem, renderPlacesOnEnter],
  );

  // Reset autosuggestlist index
  useEffect(() => {
    setSelectItem(0);
  }, [autoSuggestPlaces]);

  const fetchTagsForSelectedCity = useCallback(async () => {
    const result = await axios.get(
      `api/listings/getTags?url=${encodeURIComponent(
        selectedChoosePlace?.urlpath,
      )}`,
    );
    setTagResult(result?.data?.tags);
  }, [selectedChoosePlace?.urlpath, setTagResult]);

  // Render items in autosuggest list
  const renderAutoPlaces = useCallback(
    (autoPlace, index) => {
      const choose = () => {
        setChoosePlace(true);
        setPlace(autoPlace.title);
        setSelectedChoosePlace(autoPlace);
        setSuggestPlaces([]);
      };
      return (
        <p
          key={index}
          role="presentation"
          onClick={choose}
          className={
            selectItem === index
              ? style.renderAutoPlacesBackgroundBlue
              : 'undefined'
          }
        >
          {`${autoPlace.title} `}({autoPlace.urlpath})
        </p>
      );
    },
    [selectItem],
  );

  useEffect(() => {
    if (selectedChoosePlace?.urlpath?.length > 0) fetchTagsForSelectedCity();
  }, [fetchTagsForSelectedCity, selectedChoosePlace]);

  const filterOffers = (items) => {
    let filterSqm;
    if (sqmFilterFrom > 0 && sqmFilterTo > 0) {
      filterSqm = items.filter(
        (item) =>
          item.pricePerSqm >= sqmFilterFrom && item.pricePerSqm <= sqmFilterTo,
      );
    }
    if (sqmFilterFrom > 0) {
      filterSqm = items.filter((item) => item.pricePerSqm >= sqmFilterFrom);
    }
    if (sqmFilterTo > 0) {
      filterSqm = items.filter((item) => item.pricePerSqm <= sqmFilterTo);
    }
    return filterSqm;
  };

  useEffect(() => {
    // First if: Fetch city-data if no district is selected, else fetch district-data
    if (completeDistrictObject?.kgs || selectedChoosePlace?.kgs) {
      const fetchResultsForSelectedParameters = async () => {
        setFilteredResultsWithSqm({ items: [], loadingState: true });
        setShowResults(false);
        // This request will use API-params to retrieve numberOfItems and numberOfPages
        const resultForEntireCity = await axios.get(
          '/api/listings/getFilteredHousing',
          {
            params: {
              kgs: completeDistrictObject?.kgs || selectedChoosePlace?.kgs,
              rentOrBuy: 'rent',
              houseOrApartment: 'apartment',
              livingSpaceFrom: livingSpaceFilterFrom || null,
              livingSpaceTo: livingSpaceFilterTo || null,
            },
          },
        );
        // FUTURE: Once the API has a sqmPriceFilter, you only have to loop over the pages and collect them without
        // further logic.
        // 2nd if: Only runs, when at least 1 sqmPriceFilter is used and when district
        // is selected and has at least 1 item in it. Also searches entire city if numberOfItems is less than 400
        if (
          (sqmFilterFrom || sqmFilterTo) &&
          resultForEntireCity?.data?.data?.numberOfItems > 0 &&
          (completeDistrictObject !== '' ||
            resultForEntireCity?.data?.data?.numberOfItems < 600) &&
          selectedChoosePlace?.kgs !== completeDistrictObject?.kgs
        ) {
          // Create an array with the numberOfPages from the previous API-request for later use.
          const arrayOfPages = Array.from(
            { length: resultForEntireCity?.data?.data?.numberOfPages },
            (_, i) => i + 1,
          );
          let filteredBySqmPriceResult = [];

          setFilteredResultsWithSqm({ items: [], loadingState: true });
          // Function will map/loop over each item in the array, which means, it will fetch data for each page.
          axios
            .all(
              arrayOfPages.map((index) =>
                axios.get('/api/listings/getFilteredHousing', {
                  params: {
                    kgs: completeDistrictObject.kgs || selectedChoosePlace?.kgs,
                    rentOrBuy: 'rent',
                    houseOrApartment: 'apartment',
                    livingSpaceFrom: livingSpaceFilterFrom || null,
                    livingSpaceTo: livingSpaceFilterTo || null,
                    page: index,
                  },
                }),
              ),
            )
            .then((res) => {
              // Copy all items into one array for easier handling later on.
              // eslint-disable-next-line no-return-assign
              res.map(
                (e) =>
                  (filteredBySqmPriceResult = [
                    ...filteredBySqmPriceResult,
                    /* eslint-disable-next-line no-unsafe-optional-chaining */
                    ...e?.data?.data?.items,
                  ]),
              );
            })
            .then(() => {
              filteredBySqmPriceResult = filterOffers(filteredBySqmPriceResult);
            })
            .then(() =>
              setFilteredResultsWithSqm({
                items: filteredBySqmPriceResult,
                loadingState: false,
              }),
            );
          return;
        }
        setFilteredResultsWithSqm({ items: [], loadingState: false });
        // Return items for City, District, livingspace - without sqmFilter
        setFilteredCityResults(resultForEntireCity?.data?.data?.numberOfItems);
      };
      fetchResultsForSelectedParameters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, max-len
  }, [
    selectedChoosePlace,
    sqmFilterFrom,
    districtName,
    completeDistrictObject,
    setFilteredResultsWithSqm,
    sqmFilterTo,
    setShowResults,
    livingSpaceFilterFrom,
    livingSpaceFilterTo,
  ]);

  // Display the results depending on the selected options
  let filterResultHits;
  if (!filteredCityResults) {
    // Nothing selected
    filterResultHits = 0;
  } else if (
    filteredCityResults &&
    (sqmFilterFrom || sqmFilterTo) &&
    livingSpaceFilterFrom === '' &&
    livingSpaceFilterTo === '' &&
    !districtName
  ) {
    // City + sqmPrice selected
    filterResultHits = filteredResultsWithSqm.items.length;
  } else if (
    filteredCityResults &&
    (livingSpaceFilterFrom !== '' || livingSpaceFilterTo !== '') &&
    (sqmFilterFrom !== '' || sqmFilterTo !== '')
  ) {
    // City + Livingspace + at least 1 sqmFilter => will trigger synchronous for loop
    filterResultHits = filteredResultsWithSqm.items.length;
  } else if (
    filteredCityResults &&
    districtName?.length &&
    filteredResultsWithSqm.items.length > 0
  ) {
    // City + District + After running the loop, if there are result for filter
    filterResultHits = filteredResultsWithSqm.items.length;
  } else if (
    filteredCityResults &&
    districtName?.length &&
    (sqmFilterFrom !== '' || sqmFilterTo !== '') &&
    filteredResultsWithSqm.items.length === 0
  ) {
    // City + District + sqmFilter => NO RESULTS FOUND
    filterResultHits = 0;
  } else {
    // Combinations without smqFilter - Example: City + District + Livingspace / City + Livingspace / City + District
    filterResultHits = filteredCityResults;
  }

  return (
    <>
      {rolePermission ? (
        <div className={style.wrapper}>
          <section className="position-relative">
            <Header />
            <Parallax
              style={{ height: '550px' }}
              bgImage="/assets/img/startPage/startPage1920X900.webp"
              bgImageSrcSet="/assets/img/startPage/startPage320X320.webp 320w,
            /assets/img/startPage/startPage480X480.webp 480w,
            /assets/img/startPage/startPage800X900.webp 800w,
            /assets/img/startPage/startPage960X900.webp 900w,
            /assets/img/startPage/startPage1920X900.webp 1920w"
              strength={600}
            />
            <div
              className={classNames(
                'container u-bg-overlay__inner text-center',
                styles.blockSearch,
              )}
              style={{ top: '60%' }}
            >
              <div className="shortcode-html">
                <div
                  className={classNames(
                    'container',
                    styles.startPageInfo,
                    style.startPagePosition,
                  )}
                >
                  <h1>Mietmonitor</h1>
                  <span className="g-color-gray-dark-v3">
                    Der neutrale Marktüberblick
                  </span>
                </div>
                <div className={styles.navbg}>
                  <div
                    className={styles.searchBlock}
                    style={{ padding: '20px 30px 0 30px' }}
                  >
                    <div className={style.headlineContainer}>
                      <p className={style.headlineText}>
                        In welcher Stadt suchen Sie?
                      </p>
                      <div className="input-group">
                        <input
                          style={{ height: '100%' }}
                          value={place}
                          onChange={handlePlaceChanged}
                          type="text"
                          className="form-control g-font-size-20 border-1"
                          onKeyDown={(e) => navigateAutoPlaces(e)}
                          data-cy="autosuggest-input"
                        />
                        <div className="input-group-append">
                          <ButtonTooltip
                            setShowResults={setShowResults}
                            filteredResultsWithSqm={
                              filteredResultsWithSqm?.items
                            }
                            selectedChoosePlace={selectedChoosePlace?.title}
                            filterResultHits={filterResultHits}
                            isLoading={filteredResultsWithSqm.loadingState}
                          />
                        </div>
                      </div>
                      {autoSuggestPlaces?.length ? (
                        <div
                          className={`${styles.chooseBlock} ${style.autosuggest}`}
                        >
                          <div data-cy="autosuggest-results">
                            {autoSuggestPlaces.map(renderAutoPlaces)}
                          </div>
                        </div>
                      ) : null}
                      <div className={style.filterPosition}>
                        <DistrictsDropdown
                          tags={tagResult.length > 0 ? tagResult : []}
                          districtName={districtName}
                          setDistrictName={setDistrictName}
                          setCompleteDistrictObject={setCompleteDistrictObject}
                        />

                        <FilterBySquaremeter
                          sqmFilterFrom={sqmFilterFrom}
                          setSqmFilterFrom={setSqmFilterFrom}
                          sqmFilterTo={sqmFilterTo}
                          setSqmFilterTo={setSqmFilterTo}
                          disabled={
                            filteredCityResults > 600 ||
                            filteredCityResults === 0
                          }
                        />
                        <FilterByLivingSpace
                          livingSpaceFilterFrom={livingSpaceFilterFrom}
                          setLivingSpaceFilterFrom={setLivingSpaceFilterFrom}
                          livingSpaceFilterTo={livingSpaceFilterTo}
                          setLivingSpaceFilterTo={setLivingSpaceFilterTo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            {filteredResultsWithSqm?.items?.length > 0 && showResults && (
              <DisplayMietpolizeiResults
                filteredResultsWithSqm={filteredResultsWithSqm}
              />
            )}
          </section>
          <Footer />
        </div>
      ) : (
        <div className={style.noPermission}>
          <p>
            Ihnen fehlen die Rechte, um den Dienst
            <b> Marktmonitor </b>
            zu verweden.
            <br />
            Bitte kontaktieren Sie
            <b> info@iib-institut.de</b>, wenn Sie diesen Dienst verwenden
            wollen.
          </p>
        </div>
      )}
    </>
  );
};

export default withAuthenticationRequired(Mietpolizeit);

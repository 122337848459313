import PropTypes from 'prop-types';

import Loader from '../../../common/Loader';
import style from '../../Mietpolizei.module.scss';
import styles from '../../../Home/Home.module.scss';

const ButtonTooltip = ({
  filteredResultsWithSqm,
  selectedChoosePlace,
  filterResultHits,
  setShowResults,
  isLoading,
}) => {
  // eslint-disable-next-line max-len
  const cityAndNoFilter = (
    <p className={style.tooltip}>
      Bitte wählen Sie einen Quadratmeterpreis und ggf. einen Stadtteil.
    </p>
  );
  const noCity = (
    <p className={style.tooltip}>Bitte wählen Sie eine Stadt aus.</p>
  );

  return (
    <button
      className={`${styles.btn} ${style.btn}`}
      type="button"
      onClick={() => setShowResults(true)}
      data-cy="autosuggest-button"
      disabled={filteredResultsWithSqm.length === 0}
      style={
        filteredResultsWithSqm.length === 0
          ? {
              backgroundColor: 'gray',
              position: 'relative',
              cursor: 'not-allowed',
            }
          : { position: 'relative' }
      }
    >
      {!selectedChoosePlace && !isLoading && noCity}
      {filteredResultsWithSqm.length === 0 &&
        selectedChoosePlace &&
        !isLoading &&
        cityAndNoFilter}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {filterResultHits}
          &nbsp;Treffer
        </>
      )}
    </button>
  );
};

ButtonTooltip.propTypes = {
  filteredResultsWithSqm: PropTypes.array,
  selectedChoosePlace: PropTypes.string,
  filterResultHits: PropTypes.number,
  setShowResults: PropTypes.func,
  isLoading: PropTypes.bool,
};
ButtonTooltip.defaultProps = {
  filteredResultsWithSqm: [],
  selectedChoosePlace: '',
  filterResultHits: 0,
  setShowResults: () => '',
  isLoading: false,
};

export default ButtonTooltip;

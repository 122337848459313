import { useExcelDownloder } from 'react-xls';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const DownloadExcel = ({ filteredResultsWithSqm }) => {
  const {
    ExcelDownloder,
    Type,
    setData,
    data,
    filename,
    setFilename,
    setStyle,
  } = useExcelDownloder();

  useEffect(() => {
    setStyle({
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#990033',
      border: 'none',
      fontSize: '15px',
      color: 'white',
      padding: '10px',
    });
    if (!filteredResultsWithSqm?.length) return;
    const propertiesToDisplayInExcel = filteredResultsWithSqm?.map((item) => {
      const extractDateYMD = item.firstFoundAt.substr(0, 10);
      const selectedPropertiesToDisplay = {
        Stadt: item.city,
        Postleitzahl: item.zipCode,
        Stadtteil: item.district,
        Bezirk: item.subDivision,
        Straße: item.street,
        Hausnummer: item.houseNumber || 'unbekannt',
        Wohnfläche: item.livingSpace,
        'Netto-Kaltmiete in Euro je Quadratmeter': item.pricePerSqm,
        Einstellungsdatum: extractDateYMD,
        'Tage seit Insernierung': item.listingDuration,
        Zimmer: item.numberOfRooms,
        Warmmiete: item.warmRent || 'unbekannt',
        Kaltmiete: item.netRent,
        Baujahr: item.yearOfConstruction || 'unbekannt',
        Möbliert: item.furnished || 'unbekannt',
        'Link zum Angebot': item.urls?.is24,
      };
      return selectedPropertiesToDisplay;
    });
    // eslint-disable-next-line max-len
    const sortedSqmPriceDescending = propertiesToDisplayInExcel.sort(
      (a, b) => b.Quadratmeterpreis - a.Quadratmeterpreis,
    );
    setData({ City: sortedSqmPriceDescending });
    setFilename(filteredResultsWithSqm?.[0]?.district);
  }, [filteredResultsWithSqm, setData, setFilename, setStyle]);

  return (
    <div>
      <ExcelDownloder data={data} filename={filename} type={Type.Button}>
        {`${filteredResultsWithSqm.length} Angebote exportieren`}
      </ExcelDownloder>
    </div>
  );
};

DownloadExcel.propTypes = {
  filteredResultsWithSqm: PropTypes.array,
};

DownloadExcel.defaultProps = {
  filteredResultsWithSqm: [],
};
export default DownloadExcel;

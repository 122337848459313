import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';
import styles from './index.module.scss';

const DropDown = ({
  value,
  handleChange,
  options,
  children,
  label,
  price,
  livingSpace,
  blurFrom,
  blurTo,
}) => {
  const [expanded, setExpanded] = useState(false);

  const expand = () => {
    setExpanded(true);
  };

  const close = () => {
    setExpanded(false);
    if (children && (blurFrom || blurTo)) {
      children?.props?.children[0]?.props?.children?.props?.onBlur(blurFrom);
      children?.props?.children[2]?.props?.children?.props?.onBlur(blurTo);
    }
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      close();
    }
  };

  const handleResetFilterValue = useCallback(
    (e) => {
      e.stopPropagation();
      // Handles single input dropdowns like districts in Mietpolizei
      if (children?.props?.children?.props?.children?.props) {
        children?.props?.children?.props?.children?.props.onChange(() => '');
        return;
      }
      // Handles double inputs like pricerange inputFrom-span-inputTo
      if (children) {
        children?.props?.children[0].props.children.props.onChange(() => '');
        children?.props?.children[2].props.children.props.onChange(() => '');
      }
      handleChange('');
    },
    [children, handleChange],
  );

  useEffect(() => {
    // Reset district on selecting new city. asPath prevents resetting filter for /immobilien/city
    if (options.length > 0) {
      handleChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const renderIcons = useCallback(
    (hide) => {
      if (
        (value.length ||
          price?.[0]?.length ||
          price?.[1]?.length ||
          livingSpace?.length) &&
        !expanded &&
        !hide
      ) {
        return (
          <FontAwesomeIcon
            onMouseDown={handleResetFilterValue}
            icon={faTimesCircle}
            style={{ width: '13.55px', height: '13.55px' }}
          />
        );
      }
      if (
        (!value.length || !price?.[0]?.length || !price?.[1]?.length) &&
        expanded
      ) {
        return (
          <FontAwesomeIcon
            icon={faAngleUp}
            style={{ width: '8.75px', height: '14px' }}
          />
        );
      }
      return (
        <FontAwesomeIcon
          icon={faAngleDown}
          style={{ width: '8.75px', height: '14px' }}
        />
      );
    },
    [value.length, price, livingSpace, expanded, handleResetFilterValue],
  );

  let renderDrop;
  if (
    Object.values(children).length &&
    children?.props?.children?.[0]?.props?.children?.props?.name ===
      'LivingSpace'
  ) {
    renderDrop =
      price?.[0]?.length || price?.[1]?.length ? (
        <>
          <span>{price?.[0] ? `${price[0]} m²` : 'min'}</span>
          <span>&nbsp;-&nbsp;</span>
          <span>{price?.[1] ? `${price[1]} m²` : ' max'}</span>
        </>
      ) : (
        'Wohnfläche'
      );
  }

  if (
    Object.values(children).length &&
    children?.props?.children?.[0]?.props?.children?.props?.name ===
      'PricePerSquareMeter'
  ) {
    renderDrop =
      price?.[0]?.length || price?.[1]?.length ? (
        <>
          <span>{price?.[0]?.length ? `${price?.[0]} €/m²` : 'min'}</span>
          <span>&nbsp; - &nbsp;</span>
          <span>{price?.[1]?.length > 0 ? `${price?.[1]} €/m²` : 'max'}</span>
        </>
      ) : (
        'Preis €/m²'
      );
  }

  if (Object.values(children).length) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div className={styles.dropdownBox} onBlur={handleBlur}>
        <button
          type="button"
          onMouseDown={expanded ? close : expand}
          onKeyDown={(e) => {
            if (e.key === 'Escape' && expanded) return close();
            return expand;
          }}
        >
          {renderDrop}
          {renderIcons()}
        </button>
        <div className={classNames({ [styles.show]: expanded })}>
          {expanded && children}
        </div>
      </div>
    );
  }
  return (
    <div onBlur={close} className={styles.dropdownBox}>
      <button
        type="button"
        onMouseDown={expanded ? close : expand}
        onKeyDown={(e) => {
          if (e.key === 'Escape' && expanded) return close();
          return expand;
        }}
      >
        {value?.length ? value : label}
        {value.match(/absteigend/gi) == null &&
        value.match(/aufsteigend/gi) == null
          ? renderIcons()
          : renderIcons('hide')}
      </button>
      {expanded && options?.length > 0 && (
        <div className={classNames({ [styles.show]: expanded })}>
          {options?.map((item) => (
            <span
              role="presentation"
              data-cy="items-show"
              key={item.text || item.title}
              onMouseDown={() => {
                handleChange(item);
                close();
              }}
            >
              {item.text || item.title}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

DropDown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  children: PropTypes.object,
  price: PropTypes.array,
  livingSpace: PropTypes.string,
  blurFrom: PropTypes.string,
  blurTo: PropTypes.string,
};
DropDown.defaultProps = {
  label: '',
  value: '',
  options: [],
  children: {},
  handleChange: () => '',
  price: [],
  livingSpace: '',
  blurFrom: '',
  blurTo: '',
};
export default DropDown;

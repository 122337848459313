import classNames from 'classnames';
import Link from 'next/link';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './index.module.scss';

const Menu = () => {
  const { logout } = useAuth0();
  return (
    <div className={styles.navBlock}>
      <Link href="/">
        <img
          src="/assets/img/logo-anthranzit.png"
          alt="logo"
          height={60}
          data-cy="logo-link"
        />
      </Link>
      <div id="example-collapse-text" className={styles.show}>
        <div
          className={classNames(
            'align-items-center flex-sm-row g-pt-10 g-pt-5--lg' + 'collapse',
            styles.blockMenu,
          )}
        >
          <ul className="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto text-left">
            <li>
              <button
                type="button"
                onClick={() =>
                  logout({
                    returnTo:
                      typeof window !== 'undefined' && window.location.origin
                        ? window.location.origin
                        : '',
                  })
                }
                className={`nav-link g-py-7 g-px-0, ${styles.textColor}`}
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  fontSize: '20px',
                }}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;

import PropTypes from 'prop-types';

import { useCallback, useState } from 'react';
import DropDown from '.';
import styles from '../../../common/Filters/index.module.scss';

export const DistrictsDropdown = ({
  tags,
  districtName,
  setDistrictName,
  setCompleteDistrictObject,
}) => {
  const handleSelectDistrict = useCallback(
    (item) => {
      if (item.title === districtName) return;
      setDistrictName(item.title);
      setCompleteDistrictObject(item);
    },
    [districtName, setDistrictName, setCompleteDistrictObject],
  );

  return (
    <DropDown
      label="Stadtteil"
      options={tags}
      handleChange={handleSelectDistrict}
      value={districtName}
    />
  );
};

DistrictsDropdown.propTypes = {
  tags: PropTypes.array,
  districtName: PropTypes.string,
  setDistrictName: PropTypes.func,
  setCompleteDistrictObject: PropTypes.func,
};

DistrictsDropdown.defaultProps = {
  tags: [],
  districtName: '',
  setDistrictName: () => '',
  setCompleteDistrictObject: () => {},
};

export const FilterBySquaremeter = ({
  sqmFilterFrom,
  setSqmFilterFrom,
  sqmFilterTo,
  setSqmFilterTo,
  disabled,
}) => {
  const [displayPriceFrom, setDisplayPriceFrom] = useState('');
  const [displayPriceTo, setDisplayPriceTo] = useState('');

  // Blur will apply change either on click outside of component (first if) or by clicking it again
  // (second if), whereas the second receives the input from the filter
  const handleSqmFromBlur = useCallback(
    (e) => {
      if (e.target?.value) setSqmFilterFrom(e.target.value);
      if (typeof e === 'string') setSqmFilterFrom(e);
      else setSqmFilterFrom('');
    },
    [setSqmFilterFrom],
  );

  const handleSqmFromChange = useCallback(
    (e) => {
      if (e.target?.value) {
        setDisplayPriceFrom(e.target.value);
      } else {
        setSqmFilterFrom('');
        setDisplayPriceFrom('');
      }
    },
    [setSqmFilterFrom],
  );
  // Blur will apply change either on click outside of component (first if) or by clicking it again
  // (second if), whereas the second receives the input from the filter
  const handleSqmToBlur = useCallback(
    (e) => {
      if (e.target?.value) setSqmFilterTo(e.target.value);
      if (typeof e === 'string') setSqmFilterTo(e);
      else setSqmFilterTo('');
    },
    [setSqmFilterTo],
  );

  const handleSqmToChange = useCallback(
    (e) => {
      if (e.target?.value) {
        setDisplayPriceTo(e.target.value);
      } else {
        setSqmFilterTo('');
        setDisplayPriceTo('');
      }
    },
    [setSqmFilterTo],
  );

  return (
    <DropDown
      blurFrom={displayPriceFrom}
      blurTo={displayPriceTo}
      price={[sqmFilterFrom, sqmFilterTo]}
    >
      <div className={styles.priceBox}>
        <div>
          <input
            placeholder="€/m² von:"
            onChange={handleSqmFromChange}
            onBlur={handleSqmFromBlur}
            value={displayPriceFrom}
            name="PricePerSquareMeter"
            type="number"
            data-cy="price-show2"
            disabled={disabled}
          />
        </div>
        <span>-</span>
        <div>
          <input
            placeholder="€/m² bis"
            onBlur={handleSqmToBlur}
            onChange={handleSqmToChange}
            value={displayPriceTo}
            name="Kaltmiete"
            type="number"
            data-cy="price-show2"
            disabled={disabled}
          />
        </div>
      </div>
    </DropDown>
  );
};

FilterBySquaremeter.propTypes = {
  sqmFilterFrom: PropTypes.string,
  sqmFilterTo: PropTypes.string,
  setSqmFilterFrom: PropTypes.func,
  setSqmFilterTo: PropTypes.func,
  disabled: PropTypes.bool,
};

FilterBySquaremeter.defaultProps = {
  sqmFilterFrom: '',
  sqmFilterTo: '',
  setSqmFilterFrom: () => '',
  setSqmFilterTo: () => '',
  disabled: true,
};

export const FilterByLivingSpace = ({
  livingSpaceFilterFrom,
  setLivingSpaceFilterFrom,
  livingSpaceFilterTo,
  setLivingSpaceFilterTo,
}) => {
  const [displayLivingSpaceFrom, setDisplayLivingSpaceFrom] = useState('');
  const [displayLivingSpaceTo, setDisplayLivingSpaceTo] = useState('');

  // Blur will apply change either on click outside of component (first if) or by clicking it again
  // (second if), whereas the second receives the input from the filter
  const handleLivingSpaceFromBlur = useCallback(
    (e) => {
      if (e?.target?.value) setLivingSpaceFilterFrom(e.target.value);
      if (typeof e === 'string') setLivingSpaceFilterFrom(e);
      else setLivingSpaceFilterFrom('');
    },
    [setLivingSpaceFilterFrom],
  );

  const handleLivingSpaceFromChange = useCallback(
    (e) => {
      if (e.target?.value) {
        setDisplayLivingSpaceFrom(e.target.value);
      } else {
        setLivingSpaceFilterFrom('');
        setDisplayLivingSpaceFrom('');
      }
    },
    [setLivingSpaceFilterFrom],
  );

  // Blur will apply change either on click outside of component (first if) or by clicking it again
  // (second if), whereas the second receives the input from the filter
  const handleLivingSpaceToBlur = useCallback(
    (e) => {
      if (e?.target?.value) setLivingSpaceFilterTo(e.target.value);
      if (typeof e === 'string') setLivingSpaceFilterTo(e);
      else setLivingSpaceFilterTo('');
    },
    [setLivingSpaceFilterTo],
  );

  const handleLivingSpaceToChange = useCallback(
    (e) => {
      if (e.target?.value) {
        setDisplayLivingSpaceTo(e.target.value);
      } else {
        setLivingSpaceFilterTo('');
        setDisplayLivingSpaceTo('');
      }
    },
    [setLivingSpaceFilterTo],
  );

  return (
    <DropDown
      price={[livingSpaceFilterFrom, livingSpaceFilterTo]}
      blurFrom={displayLivingSpaceFrom}
      blurTo={displayLivingSpaceTo}
    >
      <div className={styles.priceBox}>
        <div>
          <input
            placeholder="m² von:"
            onChange={handleLivingSpaceFromChange}
            onBlur={handleLivingSpaceFromBlur}
            value={displayLivingSpaceFrom}
            name="LivingSpace"
            type="number"
            data-cy="price-show2"
          />
        </div>
        <span>-</span>
        <div>
          <input
            placeholder="m² bis"
            onBlur={handleLivingSpaceToBlur}
            onChange={handleLivingSpaceToChange}
            value={displayLivingSpaceTo}
            name="LivingSpace"
            type="number"
            data-cy="price-show2"
          />
        </div>
      </div>
    </DropDown>
  );
};
FilterByLivingSpace.propTypes = {
  livingSpaceFilterFrom: PropTypes.string,
  livingSpaceFilterTo: PropTypes.string,
  setLivingSpaceFilterFrom: PropTypes.func,
  setLivingSpaceFilterTo: PropTypes.func,
};

FilterByLivingSpace.defaultProps = {
  livingSpaceFilterFrom: '',
  livingSpaceFilterTo: '',
  setLivingSpaceFilterFrom: () => '',
  setLivingSpaceFilterTo: () => '',
};
